export default [
  {
    title: 'Tableau de bord',
    route: 'espace-admin-syndic.dashboard',
    icon: 'HomeIcon',
  },  
  {
    title: 'Copropriété',
    icon: 'ListIcon',
    children: [
      {
        title: 'Gestion des biens immobiliers',
        icon: 'DiscIcon',
        children: [
          {
            title: 'Les biens immobiliers actifs',
            route: 'espace-admin-syndic.proprietes',
            color: 'secondary',
            icon: 'DiscIcon',
          },
          {
            title: 'Les biens immobiliers suspendues',
            route: 'espace-admin-syndic.proprietes-suspendues',
            color: 'secondary',
            icon: 'DiscIcon',
          },
        ],
      },
      {
        title: 'Membre Copropriété',
        route: 'espace-admin-syndic.staff',
        icon: 'DiscIcon',
      },
      {
        title: 'Ma copropriété',
        route: 'espace-admin-syndic.copropriete',
        icon: 'DiscIcon',
      },
      {
        title: 'Frais de copropriété',
        route: 'espace-admin-syndic.frais-copropriete',
        icon: 'DiscIcon',
      },
     
    ],
  },  
  
  

]
